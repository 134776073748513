<template>
  <div >
    <div class="row" v-if="classRoom">
      <div class="col-md-12 col-lg-12 col-sm-12">
        <div class="widjet">
          <div class="widjethdr dispflex">
            <div style="width: auto">
              <span class="iconsect blubg" style="padding-left: 12px"
                ><i class="fas fa-receipt"></i
              ></span>
              <h1>Assignments</h1>
            </div>
            <!-- <div style="margin-top: 10px" class="flexitm">
              {{ assignmentList.length }} Assignment
            </div> -->
            <div style="margin-top: 10px" class="flexitm">
              {{ pendingCount }} Total
            </div>
            <div style="margin-top: 10px" class="flexitm">
              {{ submittedCount }} Received
            </div>
            <div style="margin-top: 10px" class="flexitm">
              {{ completedCount }} Completed
            </div>
            <div style="margin-top: 10px" class="flexitm">
              {{ dueCount }} Pending
            </div>
            <div style="margin-top: 10px" class="flexitm">
              <!-- <button
                v-if="assignmentView == 'List'"
                style="width: 100%"
                type="button"
                class="btn btnsml"
                @click="toggleCalendarView()"
              >
                Calender View
              </button>
              <button
                style="width: 100%"
                v-else
                type="button"
                @click="assignmentView = 'List'"
                class="btn btnsml"
              >
                List View
              </button> -->
            </div>
            <div style="margin-top: 10px" class="flexitm">
              <button
                v-if="assignmentView == 'List'"
                class="btn gry_bg tooltipt mpopup twoicn"
                @click="toggleCalendarView()"
              >
                <i class="fa fa-calendar"></i>
                <span class="tooltiptext">Calender View</span>
              </button>
              <button
                v-else
                @click="assignmentView = 'List'"
                class="btn gry_bg tooltipt mpopup twoicn"
              >
                <i class="fas fa-list-ul"></i>
                <span class="tooltiptext">List View</span>
              </button>
              <button
                class="btn gry_bg tooltipt mpopup twoicn"
                @click.prevent="addAssignment"
              >
                <i class="fas fa-receipt user1"></i>
                <p class="user2"><i class="fas fa-plus"></i></p>
                <span class="tooltiptext">Add Assignments</span>
              </button>
              <!-- <button
                style="width: 100%"
                type="button"
                class="btn btnsml"
                @click.prevent="addAssignment"
              >
                Add Assignments
              </button> -->
            </div>
          </div>
          <div class="tbllist mt-1" v-if="assignmentView == 'List'">
            <div
              class="crtfeeplancont"
              style="padding: 0 13px"
              v-if="assignmentList.length > 0"
            >
              <div
                class="widjethdr dispflex"
                style="align-items: baseline; padding: 10px 0"
              ></div>
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">Assignment Name</div>
                  <div class="cell">Total</div>
                  <div class="cell">Received</div>

                  <div class="cell">Completed</div>

                  <div class="cell">Pending</div>
                  <div class="cell">Total Marks</div>
                  <div class="cell">publish Date</div>
                  <div class="cell">Due Date</div>
                  <div class="cell">Action</div>
                </div>
                <div
                  class="resrow"
                  v-for="(item, index) in assignmentList"
                  :key="index"
                >
                  <div class="cell">{{ item.name }}</div>
                  <div class="cell">
                    {{
                      item.studentAssignment
                        ? getStatusCount(item.studentAssignment, "Pending")
                        : 0
                    }}
                  </div>
                  <div class="cell">
                    {{
                      item.studentAssignment
                        ? getStatusCount(item.studentAssignment, "Submitted")
                        : 0
                    }}
                  </div>

                  <div class="cell">
                    {{
                      item.studentAssignment
                        ? getStatusCount(item.studentAssignment, "Completed")
                        : 0
                    }}
                  </div>
                  <div class="cell">
                    {{ item.studentAssignment ? getDueCount(item, "Due") : 0 }}
                  </div>
                  <div class="cell" style="padding-left: 3%">
                    {{ item.totalMarks || "-" }}
                  </div>
                  <div class="cell">
                    {{ item.publishedDate | dateFormat }}
                  </div>
                  <div class="cell">{{ item.dueDate | dateFormat }}</div>
                  <div class="cell">
                    <b-dropdown
                      id="dropdown-1"
                      text=""
                      right
                      style="margin-right: 3px"
                    >
                      <b-dropdown-item @click.prevent="openModalTwo(item)"
                        >View Student Work</b-dropdown-item
                      >
                      <b-dropdown-item @click="editAssignment(item)"
                        >Edit</b-dropdown-item
                      >
                      <b-dropdown-item @click="confirmDelete(item._id)"
                        >Delete</b-dropdown-item
                      >
                    </b-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="crtfeeplancont"
              style="padding: 0 13px"
              v-if="assignmentList.length == 0"
            >
              <div style="margin-top: 10%; text-align: center">
                No assignments added
              </div>
            </div>
          </div>
          <div class="widjetcontent" v-else>
            <div class="crtfeeplancont" style="padding: 0 13px">
              <FullCalendar :options="calendarOptions" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="widjet" v-else style="height: 235px">
      <div style="padding-top: 92px">
        <!-- <span style="margin-left: 42%;" v-if="isLoader">Select Class And ClassRoom</span> -->
        <span style="margin-left: 42%">Please Select Classroom...</span>
      </div>
    </div>

    <b-modal
      id="modalOne"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
      <button type="button" class="close" @click.prevent="closeModalOne">
        ×
      </button>

      <h2>Add Assignment</h2>
      <br />
      <div>
        <form
          data-vv-scope="assignmentValidate"
          id="addteacher"
          accept-charset="utf-8"
        >
          <div class="row">
            <div class="col-lg-12">
              <div class="widjet">
                <div class="widjethdr dispflex">
                  <div class="form-row" style="width: 100%">
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <input
                          type="text"
                          name="asgntDetailsname"
                          class="form-control form-input"
                          id="asgntDetailsname"
                          v-validate="'required'"
                          v-model="asgntDetails.name"
                        />
                        <label
                          v-if="
                            errors.first('assignmentValidate.asgntDetailsname')
                          "
                          class="vi-error"
                          for="emailid"
                          style="top: -1em"
                          >Name is required</label
                        >
                        <label v-else for="ctid"
                          >Name <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <select
                          name="assType"
                          class="form-control form-input"
                          id="assType"
                          v-validate="'required'"
                          v-model="asgntDetails.type"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in typeList"
                            :key="index"
                            :value="item.value"
                          >
                            {{ item.label }}
                          </option>
                        </select>
                        <label
                          v-if="errors.first('assignmentValidate.assType')"
                          class="vi-error"
                          for="emailid"
                          style="top: -1em"
                          >Type is required</label
                        >

                        <label for="typeId" v-else
                          >Type <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <select
                          name="asssubject"
                          class="form-control form-input"
                          id="asssubject"
                          v-validate="'required'"
                          v-model="asgntDetails.subject"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in teacherAllowSubjects"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.subjectName }}
                          </option>
                        </select>
                        <label
                          v-if="errors.first('assignmentValidate.asssubject')"
                          class="vi-error"
                          for="emailid"
                          style="top: -1em"
                          >Subject is required</label
                        >
                        <label for="typeId" v-else
                          >Subject <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <input
                          type="date"
                          name="publishDate"
                          class="form-control form-input"
                          id="publishDateId"
                          v-validate="'required'"
                          v-model="asgntDetails.publishedDate"
                        />
                        <label
                          v-if="errors.first('assignmentValidate.publishDate')"
                          class="vi-error"
                          for="emailid"
                          style="top: -1em"
                          >Publish Date is required</label
                        >
                        <label v-else for="publishDateId">Publish Date</label>
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <input
                          type="date"
                          name="aadueDate"
                          class="form-control form-input"
                          id="aadueDate"
                          v-validate="'required'"
                          v-model="asgntDetails.dueDate"
                        />
                        <label
                          v-if="errors.first('assignmentValidate.aadueDate')"
                          class="vi-error"
                          for="emailid"
                          style="top: -1em"
                          >Due Date is required</label
                        >
                        <label v-else for="publishDateId">Due Date</label>
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <select
                          name="assSubType"
                          v-validate="'required'"
                          class="form-control form-input"
                          id="typeId"
                          v-model="asgntDetails.submissionType"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in submissionTypeList"
                            :key="index"
                            :value="item.value"
                          >
                            {{ item.label }}
                          </option>
                        </select>
                        <label
                          v-if="errors.first('assignmentValidate.assSubType')"
                          class="vi-error"
                          for="emailid"
                          style="top: -1em"
                          >Submission Type is required</label
                        >
                        <label for="typeId" v-else
                          >Submission Type
                          <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <input
                          type="number"
                          name="totalMarks"
                          class="form-control form-input"
                          id="totalMarksId"
                          v-validate="'required'"
                          v-model="asgntDetails.totalMarks"
                        />
                        <label
                          v-if="errors.first('assignmentValidate.totalMarks')"
                          class="vi-error"
                          for="emailid"
                          style="top: -1em"
                          >Total Marks is required</label
                        >
                        <label v-else for="totalMarksId"
                          >Total Marks <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <!-- <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                      <span style="color: #6c757d" class="mr-2">
                        Evaluation</span
                      >
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          v-model="asgntDetails.needEvaluation"
                        />
                        <div class="slider"></div>
                      </label>
                    </div> -->
                    <!-- <div
                      class="form-group input-group col-md-4 mt-3 pr-md-3"
                    ></div> -->
                    <div class="form-group input-group col-md-8 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <textarea
                          class="form-control form-input"
                          style="min-height: 33px; resize: none"
                          id="descriptionId"
                          v-model="asgntDetails.description"
                        ></textarea>
                        <label for="descriptionId">Description</label>
                      </span>
                    </div>
                    <!-- <div class="form-row" style="width: 100%"v-if="!asgntDetails._id"> -->
                    <!-- <div
                      class="form-group input-group col-md-4 mt-3 pr-md-3"
                    ></div> -->
                    <div class="form-group input-group col-md-2 mt-3 pr-md-3">
                      Attachments
                    </div>
                    <div class="form-group input-group col-md-10 mt-3 pr-md-3">
                      <input
                        v-if="asgntDetails.attachments.length == 0"
                        type="file"
                        id="importExportID"
                        ref="attachDoc"
                        name="attachDoc"
                        accept="application/pdf,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/jpg, image/png"
                        @change="updateFile('attachDoc')"
                      />
                      <span v-if="asgntDetails.attachments.length > 0">{{
                        asgntDetails.attachments[0].name
                      }}</span>

                      <button
                        v-if="asgntDetails.attachments.length > 0"
                        class="btn btn-doc btn-sm"
                        style="background-color: darkseagreen; margin-left: 10%"
                        @click.prevent="
                          preViewDocument(
                            asgntDetails.attachments[0].url.Location
                          )
                        "
                      >
                        View
                      </button>
                      <button
                        v-if="asgntDetails.attachments.length > 0"
                        class="btn btn-doc btn-sm"
                        style="margin-left: 7px; background-color: indianred"
                      >
                        Delete
                      </button>
                    </div>
                    <div
                      class="form-group input-group col-md-1 mt-3 pr-md-3"
                      v-if="!asgntDetails._id"
                    >
                      Assign
                    </div>

                    <!-- <input
                      v-if="!asgntDetails._id"
                      class="form-group input-group col-md-1 mt-3 pr-md-3"
                      type="radio"
                      id="html"
                      name="fav_language"
                      v-model="asgntDetails.assignType"
                      value="class"
                      @click="getStudent('class')"
                    />
                     
                    <label for="Class" class="mt-3" v-if="!asgntDetails._id"
                      >Class</label
                    ><br /> -->
                     
                    <input
                      v-if="!asgntDetails._id"
                      class="form-group input-group col-md-1 mt-3 pr-md-3"
                      type="radio"
                      id="css"
                      v-model="asgntDetails.assignType"
                      value="classRoom"
                      name="fav_language"
                      @click="getStudent('classRoom')"
                    />
                     
                    <label
                      for="Class Room"
                      class="mt-3"
                      v-if="!asgntDetails._id"
                      >Classroom</label
                    ><br />
                     
                    <input
                      v-if="!asgntDetails._id"
                      class="form-group input-group col-md-1 mt-3 pr-md-3"
                      type="radio"
                      id="javascript"
                      v-model="asgntDetails.assignType"
                      value="student"
                      name="fav_language"
                      @click="getStudent('student')"
                    />
                     
                    <label for="Student" class="mt-3" v-if="!asgntDetails._id"
                      >Student</label
                    >

                    <div
                      v-if="!asgntDetails._id"
                      class="form-group input-group col-md-12 mt-3 pr-md-3"
                    ></div>

                    <!-- <div class="form-group input-group col-md-3 mt-3 pr-md-3">Class</div>
                <div class="form-group input-group col-md-3 mt-3 pr-md-3">Class Room</div>
                <div class="form-group input-group col-md-3 mt-3 pr-md-3">Student</div> -->

                    <div
                      v-if="
                        showFullSection &&
                        !asgntDetails._id &&
                        asgntDetails.assignType != 'student'
                      "
                      style="width: 100%; display: flex"
                    >
                      <div style="width: 30%" v-if="showClassSelection">
                        <span class="has-float-label">
                          <select
                            class="form-control form-input"
                            id="deptlistid"
                            disabled
                            v-model="selectedClass"
                            @change.prevent="getSection(selectedClass)"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in classList"
                              :key="index"
                              :value="item._id"
                            >
                              {{ item.className }}
                            </option>
                          </select>
                          <label for="clslistid">Select Class </label>
                        </span>
                      </div>

                      <div
                        style="width: 30%"
                        class="ml-4"
                        v-if="showSectionSelection"
                      >
                        <span class="has-float-label">
                          <select
                            class="form-control form-input"
                            id="deptlistid"
                            v-model="selectedSection"
                            @change="getStudentList(selectedSection)"
                          >
                            <option :value="null">-- Select --</option>

                            <option
                              v-for="(item, index) in sectionList"
                              :key="index"
                              :value="item._id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                          <label for="clslistid">Select Classroom </label>
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="asgntDetails.assignType == 'student'"
                      style="width: 100%; display: flex"
                    >
                      <div style="width: 30%">
                        <span class="has-float-label">
                          <select
                            class="form-control form-input"
                            id="deptlistid"
                            v-model="classId"
                            disabled
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in classList"
                              :key="index"
                              :value="item._id"
                            >
                              {{ item.className }}
                            </option>
                          </select>
                          <label for="clslistid">Select Class </label>
                        </span>
                      </div>

                      <div style="width: 30%" class="ml-4">
                        <span class="has-float-label">
                          <select
                            class="form-control form-input"
                            id="deptlistid"
                            disabled
                            v-model="classRoom"
                          >
                            <option :value="null">-- Select --</option>

                            <option
                              v-for="(item, index) in sectionList"
                              :key="index"
                              :value="item._id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                          <label for="clslistid">Select Classroom </label>
                        </span>
                      </div>
                    </div>

                    <div
                      v-if="showClassTable && !asgntDetails._id"
                      style="width: 100%"
                    >
                      <div class="tbllist mt-1">
                        <div class="crtfeeplancont" style="padding: 0 13px">
                          <div
                            class="widjethdr dispflex"
                            style="align-items: baseline; padding: 10px 0"
                          ></div>
                          <div class="restable">
                            <div class="resrow resheader">
                              <div class="cell"></div>
                              <div class="cell">Class Name</div>
                            </div>
                            <div
                              class="resrow"
                              v-for="(item, index) in classList"
                              :key="index"
                            >
                              <div class="cell">
                                <label
                                  class="switch"
                                  style="margin-bottom: 0rem"
                                >
                                  <input
                                    type="checkbox"
                                    id="sibtogBtn"
                                    v-model="item.isSelected"
                                  />
                                  <div class="slider"></div>
                                </label>
                                <!-- <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"> -->
                                <!-- <input type="checkbox" :id="item._id" :name="item.className" :value="item.className"> -->
                              </div>
                              <div class="cell">{{ item.className }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="showSectionTable && !asgntDetails._id"
                      style="width: 100%"
                    >
                      <div class="tbllist mt-1">
                        <div class="crtfeeplancont" style="padding: 0 13px">
                          <div
                            class="widjethdr dispflex"
                            style="align-items: baseline; padding: 10px 0"
                          ></div>
                          <div class="restable">
                            <div class="resrow resheader">
                              <div class="cell">S.No</div>
                              <div class="cell">Section Name</div>
                            </div>
                            <div
                              class="resrow"
                              v-for="(item, indexs) in sectionList"
                              :key="indexs"
                            >
                              <div class="cell">
                                <label
                                  class="switch"
                                  style="margin-bottom: 0rem"
                                >
                                  <input
                                    type="checkbox"
                                    id="sibtogBtn"
                                    v-model="item.isSelected"
                                  />
                                  <div class="slider"></div>
                                </label>
                                <!-- <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"> -->
                                <!-- <input type="checkbox" :id="item._id" :name="item.className" :value="item.className"> -->
                              </div>
                              <div class="cell">
                                {{ item.name }}
                                <!-- {{ getClassName(item.class) }} - -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="showStudentTable && !asgntDetails._id"
                      style="width: 100%"
                    >
                      <div class="tbllist mt-1">
                        <div class="crtfeeplancont" style="padding: 0 13px">
                          <div
                            class="widjethdr dispflex"
                            style="align-items: baseline; padding: 10px 0"
                          ></div>
                          <div class="restable">
                            <div class="resrow resheader">
                              <div class="cell">S.No</div>
                              <div class="cell">Student Name</div>
                            </div>
                            <div
                              class="resrow"
                              v-for="(item, index) in studentList"
                              :key="index"
                            >
                              <div class="cell">
                                <label
                                  class="switch"
                                  style="margin-bottom: 0rem"
                                >
                                  <input
                                    type="checkbox"
                                    id="sibtogBtn"
                                    v-model="item.isSelected"
                                  />
                                  <div class="slider"></div>
                                </label>
                                <!-- <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"> -->
                                <!-- <input type="checkbox" :id="item._id" :name="item.className" :value="item.className"> -->
                              </div>
                              <div class="cell">
                                <!-- {{ getClassName(item.class) }} - -->
                                {{ item.firstName }} {{ item.lastName }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
                </div>

                <div class="widjetfooter text-center dkicon">
                  <button
                    type="button"
                    class="btn btnsml"
                    id="svbtn"
                    @click="saveAssignment"
                  >
                    <span>Save</span>
                  </button>
                  <button
                    type="button"
                    @click="closeModalOne"
                    class="btn btncl clsmdl"
                  >
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-modal>

    <b-modal
      id="modalTwo"
      style="width: 100%"
     
    >
      <button type="button" class="close" @click.prevent="closeModalTwo">
        ×
      </button>
      <div class="p-3 widjetcontent">
        <div class="tabs" style="height: 2%; background: none">
          <input
            type="radio"
            name="tabs"
            @click="setActiveTab(1)"
            id="classesSection"
            :checked="currentTab == 1 ? 'checked' : ''"
          />
          <label
            for="classesSection"
            style="
              border: 1px solid rgb(192, 196, 233);
              border-radius: 15px 15px 0px 0px;
            "
            >Submitted ({{ studentsEvaluationSubmitedList.length  }})
          </label>

          <div class="tab">
          <div v-if="studentsEvaluationSubmitedList.length > 0">
            <form
              data-vv-scope="crtAdhocFeeValidate"
              id="crtfeeplans"
              accept-charset="utf-8"
            >
            <div class="tbllist mt-1" style="height: 414px" >
              <div
                style="height: 414px; overflow-y: scroll; overflow-x: hidden"
              >
                <div class="resrow resheader">
                  <div class="cell col-1">Class</div>
                  <div class="cell col-1">Student Name</div>
                  <div class="cell col-2">Status</div>
                  <div class="cell col-1">Marks</div>

                  <div class="cell col-7">Feedback</div>
                  <div class="cell" style="text-align: center">Action</div>
                </div>
                <div
                  class="resrow"
                  v-for="(item, index) in studentsEvaluationSubmitedList"
                  :key="index"
                >
                  <div class="cell col-1">
                    {{
                      getClassName(item.class) +
                      "/" +
                      getSectionName(item.classRoom)
                    }}
                  </div>
                  <!-- <div class="cell">
                              {{ getSectionName(item.classRoom) }}
                            </div> -->
                  <div class="cell col-1">{{ item.studentName }}</div>
                  <div class="cell col-2">{{ item.status || ''}}
                   
                  </div>
                  <div class="cell col-1" style="width: 8%">
                    <span class="has-float-label">
                      <input
                        ref="inputFieldmarksubmitted"
                        :name="index + 'checkmark'"
                        @keyup.enter="handleEnterKeyMark('submited',index, 'mark')"
                        type="number"
                        :max="termTotalMarks"
                        :min="0"
                        v-model="item.mark"
                        class="form-control"
                      />
                      <span
                        class="required"
                        v-if="errors.first(`crtAdhocFeeValidate.${index + 'checkmark'}`)">Maximum Marks {{ termTotalMarks }}</span
                      >
                      <!-- <label for="markId">Marks</label> -->
                    </span>
                  </div>
                  <div class="cell col-7">
                    <span class="has-float-label">
                      <input
                        type="text"
                        v-model="item.recieveNote"
                        class="form-control"
                        ref="inputFieldFeedbackSubmitted"
                        :name="index + 'feedback'"
                        @keyup.enter="handleEnterKeyNote('submited',index, 'recieveNote')"
                      />
                      <!-- <label for="feedbackId">Feedback</label> -->
                    </span>
                  </div>

                  <div class="cell" style="text-align: center">
                    <b-dropdown
                      id="dropdown-1"
                      text=""
                      right
                      style="margin-right: 3px"
                    >
                      <b-dropdown-item   @click="viewMaterialPopup(item)"
                        >View Attachment</b-dropdown-item
                      >
                      <b-dropdown-item @click="editAssignmentStatus(item,index)"
                        >Mark as Completed</b-dropdown-item
                      >
                      <!-- <b-dropdown-item @click="confirmDelete(item._id)"
                        >Delete</b-dropdown-item
                      > -->
                    </b-dropdown>
                  </div>
                </div>
              </div>

              <div class="widjetfooter text-center dkicon">
                <button
                  @click="updateStudentAssignment(studentsEvaluationSubmitedList,'Submitted')"
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                >
                  <span>Save</span>
                </button>
                <button
                  type="button"
                  @click="closeModalTwo"
                  class="btn btncl clsmdl"
                >
                  <span>Back</span>
                </button>
                <!-- </div> -->
              </div>
            </div>
          </form>
          </div>
            <div  v-else style="height: 235px;">
          <div style="padding-top: 92px;" >
            <span style="margin-left: 42%;" >No Submitted Records</span>
          </div>
        </div>
          </div>
          <input
            type="radio"
            name="tabs"
            :checked="currentTab == 2 ? 'checked' : ''"
            @click="setActiveTab(2)"
            id="assignedClass"
          />
          <label
            for="assignedClass"
            style="
              border: 1px solid rgb(192, 196, 233);
              border-radius: 15px 15px 0px 0px;
            "
            >Pending ({{ studentsEvaluationPendingList.length }})
          </label>
          <div class="tab">
            <div v-if="studentsEvaluationPendingList.length > 0">
              <form
              data-vv-scope="pendingValidate"
              id="crtfeeplans"
              accept-charset="utf-8"
            >
            <div class="tbllist mt-1" style="height: 414px" >
              <div
                style="height: 414px; overflow-y: scroll; overflow-x: hidden"
              >
                <div class="resrow resheader">
                  <div class="cell col-1">Class</div>
                  <div class="cell col-1">Student Name</div>
                  <div class="cell col-2">Status</div>
                  <div class="cell col-1">Marks</div>

                  <div class="cell col-7">Feedback {{ termTotalMarks }}</div>
                  <div class="cell" style="text-align: center">Action</div>
                </div>
                <div
                  class="resrow"
                  v-for="(item, index) in studentsEvaluationPendingList"
                  :key="index"
                >
                  <div class="cell">
                    {{
                      getClassName(item.class) +
                      "/" +
                      getSectionName(item.classRoom)
                    }}
                  </div>
                 
                  <div class="cell">{{ item.studentName }}</div>
                  <div class="cell">{{ item.status || ''}}
                   
                  </div>
                  <div class="cell" style="width: 8%">
                    <span class="has-float-label">
                      <input
                        :min="0"
                        ref="inputFieldmarkpending"
                        :name="index + 'checkmarkPending'"
                        @keyup.enter="handleEnterKeyMark('pending',index, 'mark')"
                        type="number"
                        :max="termTotalMarks"
                        v-model="item.mark"
                        class="form-control"
                      />
                      <span
                        class="required"
                        v-if="errors.first(`pendingValidate.${index + 'checkmarkPending'}`)">Maximum Marks {{ termTotalMarks }}</span
                      >
                    </span>
                  </div>
                  <div class="cell">
                    <span class="has-float-label">
                      <input
                        type="text"
                        v-model="item.recieveNote"
                        class="form-control"
                        ref="inputFieldFeedbackpending"
                        :name="index + 'feedback'"
                        @keyup.enter="handleEnterKeyNote('pending',index, 'recieveNote')"
                      />
                    </span>
                  </div>

                  <div class="cell" style="text-align: center">
                    <!-- <button
                      @click="viewMaterialPopup(item)"
                      class="btn round orange tooltipt"
                    >
                      <i class="far fa-eye"></i
                      ><span class="tooltiptext">View</span>
                    </button> -->
                    <b-dropdown
                      id="dropdown-1"
                      text=""
                      right
                      style="margin-right: 3px"
                    >
                      <b-dropdown-item   @click="viewMaterialPopup(item)"
                        >View Attachment</b-dropdown-item
                      >
                      <!-- <b-dropdown-item @click="editAssignment(item)"
                        >Mark as Completed</b-dropdown-item
                      > -->
                      <!-- <b-dropdown-item @click="confirmDelete(item._id)"
                        >Delete</b-dropdown-item
                      > -->
                    </b-dropdown>
                  </div>
                </div>
              </div>

              <div class="widjetfooter text-center dkicon">
                <button
                  @click="updateStudentAssignment(studentsEvaluationPendingList,'Pending')"
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                >
                  <span>Save</span>
                </button>
                <button
                  type="button"
                  @click="closeModalTwo"
                  class="btn btncl clsmdl"
                >
                  <span>Back</span>
                </button>
                <!-- </div> -->
              </div>
            </div>
          </form>
          </div>
            <div  v-else style="height: 235px;">
          <div style="padding-top: 92px;" >
            <span style="margin-left: 42%;" >No Pending Records</span>
          </div>
        </div>
          </div>
          <input
            type="radio"
            name="tabs"
            :checked="currentTab == 3 ? 'checked' : ''"
            @click="setActiveTab(3)"
            id="fullPaid"
          />
        
          <input
            type="radio"
            name="tabs"
            :checked="currentTab == 4 ? 'checked' : ''"
            @click="setActiveTab(4)"
            id="completded"
          />
          <label
            for="completded"
            style="
              border: 1px solid rgb(192, 196, 233);
              border-radius: 15px 15px 0px 0px;
            "
            >Completed ({{  studentsEvaluationCompleteList.length  }})
          </label>
          <div class="tab">
            <div class="tbllist mt-1" style="height: 414px" v-if="studentsEvaluationCompleteList.length > 0">
              <div
                style="height: 414px; overflow-y: scroll; overflow-x: hidden"
              >
                <div class="resrow resheader">
                  <div class="cell col-1">Class</div>
                  <div class="cell col-1">Student Name</div>
                  <div class="cell col-2">Status</div>
                  <div class="cell col-1">Marks</div>

                  <div class="cell col-7">Feedback</div>
                  <div class="cell" style="text-align: center">Action</div>
                </div>
                <div
                  class="resrow"
                  v-for="(item, index) in studentsEvaluationCompleteList"
                  :key="index"
                >
                  <div class="cell">
                    {{
                      getClassName(item.class) +
                      "/" +
                      getSectionName(item.classRoom)
                    }}
                  </div>
                 
                  <div class="cell">{{ item.studentName }}</div>
                  <div class="cell">{{ item.status || ''}}
                  
                  </div>
                  <div class="cell" style="width: 8%">
                    <span class="has-float-label">
                      <input
                      disabled
                       
                        type="number"
                        v-model="item.mark"
                        class="form-control"
                      />
                    </span>
                  </div>
                  <div class="cell">
                    <span class="has-float-label">
                      <input
                        type="text"
                        disabled
                        v-model="item.recieveNote"
                        class="form-control"
                       
                      />
                    </span>
                  </div>

                  <div class="cell" style="text-align: center">
                    <!-- <button
                      @click="viewMaterialPopup(item)"
                      class="btn round orange tooltipt"
                    >
                      <i class="far fa-eye"></i
                      ><span class="tooltiptext">View</span>
                    </button> -->
                    <b-dropdown
                      id="dropdown-1"
                      text=""
                      right
                      style="margin-right: 3px"
                    >
                      <b-dropdown-item   @click="viewMaterialPopup(item)"
                        >View Attachment</b-dropdown-item
                      >
                      <!-- <b-dropdown-item @click="editAssignment(item)"
                        >Mark as Completed</b-dropdown-item
                      > -->
                      <!-- <b-dropdown-item @click="confirmDelete(item._id)"
                        >Delete</b-dropdown-item
                      > -->
                    </b-dropdown>
                  </div>
                </div>
              </div>

              <div class="widjetfooter text-center dkicon">
                <!-- <button
                  @click="updateStudentAssignment(studentsEvaluationCompleteList)"
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                >
                  <span>Save</span>
                </button> -->
                <button
                  type="button"
                  @click="closeModalTwo"
                  class="btn btncl clsmdl"
                >
                  <span>Back</span>
                </button>
                <!-- </div> -->
              </div>
            </div>
            <div  v-else style="height: 235px;">
          <div style="padding-top: 92px;" >
            <span style="margin-left: 42%;" >No Completed Records</span>
          </div>
        </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="materialPopup"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
      <button type="button" class="close" @click.prevent="closePoup">×</button>

      <div class="contact-form">
        <h2>Attachments</h2>
        <br />
        <div>
          <form id="crtfeeplans" accept-charset="utf-8">
            <div
              class="row"
              v-if="
                uploadDocment &&
                uploadDocment.documents &&
                uploadDocment.documents.length > 0
              "
            >
              <div class="col-lg-12">
                <!-- <div class="form-row" >
                           <input
                            type="file"
                            id="importExportID"
                            ref="attachDoc"
                            name="attachDoc"
                            accept="application/pdf,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/jpg, image/png"
                            @change="updateFile('attachDoc')"
                           />
              </div> -->
                <div
                  class="form-row"
                  style="width: 100%"
                  v-for="(doc, indexDoc) in uploadDocment.documents"
                  :key="indexDoc"
                >
                  <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                    <span class="has-float-label">
                      <input
                        v-model="doc.name"
                        disabled
                        class="form-control form-input"
                        id="fpstartdateid"
                      />
                      <label for="fpstartdateid">File Name</label>
                    </span>
                  </div>
                  <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                    <span class="has-float-label">
                      <input
                        v-model="doc.date"
                        disabled
                        class="form-control form-input"
                        id="fpstartdateid"
                      />
                      <label for="fpstartdateid">File Upload Date</label>
                    </span>
                  </div>
                  <div class="form-group input-group col-md-2 mt-3">
                    <button
                      class="btn btn-doc btn-sm"
                      style="background-color: darkseagreen"
                      @click.prevent="preViewDocument(doc.url.Location)"
                    >
                      View
                    </button>
                    <!-- <button class="btn btn-doc btn-sm" style="margin-left: 7px;background-color: indianred;" @click.prevent="removeDocument(indexDoc,doc.url.key)">
                              Delete
                            </button> -->
                    <!-- //key -->
                    <!-- <button class="btn round red tooltipt"   style="height:1.8rem"><i class="far fa-trash-alt"></i><span class="tooltiptext">Delete</span></button> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-lg-12" style="text-align: center">
                No Attachments
              </div>
              <!-- <span>sdsdsds</span> -->
            </div>
          </form>

          <div class="mt-3 pt-3" style="border-top: 1px solid #e5e5e5">
            <!-- <div class="dk_icon"></div> -->
            <div class="text-center dk_iconsml">
              <button
                @click.prevent="closePoup"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                <span>Close</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="resultPopUp"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div class="form-row" style="width: 100%">
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <input
                    type="text"
                    style="margin-left: 4%"
                    placeholder="Search Students"
                  />
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <button
                    type="button"
                    style="width: 24%"
                    class="btn btnsml"
                    id="svbtn"
                  >
                    Contribution
                  </button>
                  <button
                    type="button"
                    style="width: 24%"
                    class="btn btnsml"
                    id="svbtn"
                  >
                    Calculate
                  </button>
                  <button
                    type="button"
                    style="width: 24%"
                    class="btn btnsml"
                    id="svbtn"
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>

            <div class="tbllist mt-1">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">S.No</div>
                  <div class="cell">Student Name</div>
                  <div class="cell">Grade</div>
                  <div class="cell">%</div>
                  <div class="cell">Ranks</div>
                  <div class="cell">Marks</div>
                  <div class="cell">Absent/Present</div>
                  <div class="cell">Comments</div>
                </div>

                <div class="resrow">
                  <div class="cell">1</div>
                  <div class="cell">Saran</div>
                  <div class="cell">A+</div>
                  <div class="cell">100%</div>
                  <div class="cell">1</div>
                  <div class="cell">9119</div>
                  <div class="cell">Present</div>
                  <div class="cell">
                    <textarea
                      name="comment"
                      class="form-control form-input"
                      style="min-height: 40px; resize: none"
                      id="tocmts"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="widjetfooter text-right dkicon">
              <button
                type="button"
                @click="closeResultPopUp"
                class="btn btncl clsmdl"
              >
                <span>Back</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
//import timeGridPlugin from '@fullcalendar/timegrid';
//import interactionPlugin from '@fullcalendar/interaction';

import secureUI from "../../../utils/secureUI";
import ViService from "@/services/ViService";

export default {
  name: "AppTeacherAcademicManagementAssignment",
  props: {
    classId: {
      type: String,
    },
    classRoom: {
      type: String,
    },
    assignedSubjects: {
      type: Array,
    },
  },
  data() {
    return {
      calendarOptions: {
        // dayGridPlugin,
        //   timeGridPlugin,
        //   interactionPlugin // needed for dateClick
        //  plugins: [dayGridPlugin,timeGridPlugin,interactionPlugin],
        plugins: [dayGridPlugin],
        headerToolbar: {
          left: "title",
          center: "",
          right: "prev,next",
        },
        initialView: "dayGridMonth",
        weekends: true,
        displayEventTime: false,
        events: [],
      },
      currentTab: 1,
      uploadDocment: {},
      evaluationList: [],
      studentsEvaluationSubmitedList: [],
      studentsEvaluationPendingList: [],
      studentsEvaluationDueList: [],
      studentsEvaluationCompleteList: [],

      assignmentView: "List",
      showSetupTimeTable: false,
      periodList: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      createPeriodList: [
        {
          day: "Mon",
        },
        {
          day: "Tue",
        },
        {
          day: "Wed",
        },
        {
          day: "Thu",
        },
        {
          day: "Fri",
        },
        {
          day: "Sat",
        },
      ],
      submittedCount: 0,
      pendingCount: 0,
      completedCount: 0,
      termTotalMarks: null,
      dueCount: 0,
      assignmentDetails: {
        view: "",
        from: "",
        to: "",
        skip: 0,
        limit: 100,
        class: this.classId,
        classroom: this.classRoom,
        needCount: true,
      },
      assignmentList: [],
      assignedStudentList: [],
      classList: [],
      allSectionList: [],
      typeList: [
        {
          label: "Homework",
          value: "Homework",
        },
        {
          label: "Quiz",
          value: "Quiz",
        },
        {
          label: "Test",
          value: "Test",
        },
        {
          label: "Online Class",
          value: "Online Class",
        },
        {
          label: "Offline Class",
          value: "Offline Class",
        },
        {
          label: "Remedial",
          value: "Remedial",
        },
        {
          label: "Worksheet",
          value: "Worksheet",
        },
        {
          label: "Holiday Homework",
          value: "Holiday Homework",
        },
      ],
      statusList: [
        {
          label: "Submitted",
          value: "Submitted",
        },
        {
          label: "Completed",
          value: "Completed",
        },
        {
          label: "Due",
          value: "Due",
        },
      ],
      subjectList: [],
      teacherAllowSubjects: [],
      submissionTypeList: [
        {
          label: "Online Submission",
          value: "Online Submission",
        },
        {
          label: "Hard Copy",
          value: "Hard Copy",
        },
        {
          label: "URL Link",
          value: "URL Link",
        },
        {
          label: "Google Classroom",
          value: "Google Classroom",
        },
        {
          label: "Hindi",
          value: "Hindi",
        },
      ],
      asgntDetails: {
        _id: null,
        name: "",
        type: "",
        subject: "",
        publishedDate: null,
        dueDate: null,
        submissionType: "",
        totalMarks: null,
        attachments: [],
        needEvaluation: false,
        description: "",
        assignType: "class",
        classes: [],
        classrooms: [],
        students: [],
        createClass: this.classId,
        createSection: this.classRoom,
        isDeleted: false,
        accountId: null,
        createdDate: new Date(),
        createdBy: "",
        updatedBy: "",
        updatedDate: new Date(),
      },
      sectionList: [],
      studentList: [],
      selectedClass: null,
      selectedSection: null,
      showClassSelection: false,
      showSectionSelection: false,
      showFullSection: false,
      showClassTable: false,
      showSectionTable: false,
      showStudentTable: false,
    };
  },
  created() {
    this.getAssignmentList();
    this.getClassWithSection();
    this.getAllSectionList();
    this.getSubjectList();
    this.getAllTeacherClassAssignments();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
  },

  methods: {
    getStatusCount(data, type) {
      if (data.length > 0) {
        if (type == "Submitted") {
          const submittedDetails = data.filter(
            (x) =>
              (x.status == "Submitted" || x.status == 'Late Submission') &&
              x.classRoom.toString() == this.classRoom.toString()
          );
          return submittedDetails.length;
        }
        if (type == "Pending") {
          const submittedDetails = data.filter(
            (x) => x.classRoom.toString() == this.classRoom.toString()
          );
          return submittedDetails.length;
        }

        if (type == "Completed") {
          const completedDetails = data.filter(
            (x) =>
              x.status == "Completed" &&
              x.classRoom.toString() == this.classRoom.toString()
          );
          return completedDetails.length;
        }
        if (type == "Due") {
          const submittedDetails = data.filter(
            (x) =>
              x.status == "Pending" &&
              x.classRoom.toString() == this.classRoom.toString()
          );

          // if( x.dueDate < new Date()){

          // }
          return 0;
        }
      }
    },
    getDueCount(data, type) {
      if (data.studentAssignment.length > 0) {
        const submittedDetails = data.studentAssignment.filter(
          (x) =>
            x.status == "Pending" &&
            x.classRoom.toString() == this.classRoom.toString()
        );
        return submittedDetails.length;
      }
    },
    viewMaterialPopup(data) {
      this.uploadDocment = data;
      this.uploadDocment.documents = this.uploadDocment.documents || [];
      this.$bvModal.show("materialPopup");
    },
    closePoup() {
      this.$bvModal.hide("materialPopup");
    },
    openAssignTeacherPopUp() {
      this.$bvModal.show("assignTeacherPopUp");
    },
    closeAssignTeacherPopUp() {
      this.$bvModal.hide("assignTeacherPopUp");
    },
    openResultPopUp() {
      this.$bvModal.show("resultPopUp");
    },
    closeResultPopUp() {
      this.$bvModal.hide("resultPopUp");
    },

    openClassPerformance() {
      this.$bvModal.show("classPerformancePopUp");
    },
    closeClassPerformancePopUp() {
      this.$bvModal.hide("classPerformancePopUp");
    },
    openCurriculumPopUp() {
      this.$bvModal.show("curriculumPopUp");
    },
    closeCurriculumPopUp() {
      this.$bvModal.hide("curriculumPopUp");
    },
    openCreateTimeTablePopUp() {
      this.$bvModal.show("createTimeTablePopUp");
    },
    closeCreateTimeTablePopUp() {
      this.$bvModal.hide("createTimeTablePopUp");
    },
    enableSetupTimeTable() {
      this.showSetupTimeTable = true;
    },
    closeSetupTimeTable() {
      this.showSetupTimeTable = false;
    },
    toggleCalendarView() {
      this.calendarOptions.events = this.assignmentList.map((val) => {
        return {
          title: val.name,
          start: new Date(val.dueDate),
          ...val,
        };
      });

      this.assignmentView = "Calender";
    },
    async handleEnterKeyMark(type,rowIndex, columnName) {
      if(type == 'submited'){
      const nextRowIndex = rowIndex + 1;
      const nextColumnName = columnName;

      if (this.studentsEvaluationSubmitedList[nextRowIndex]) {
        this.$nextTick(() => {
          const nextInputField = this.$refs.inputFieldmarksubmitted[nextRowIndex];

          if (nextInputField) {
            nextInputField.focus();
          }
        });
      }
    }
    if(type == 'pending'){
      const nextRowIndex = rowIndex + 1;
      const nextColumnName = columnName;

      if (this.studentsEvaluationPendingList[nextRowIndex]) {
        this.$nextTick(() => {
          const nextInputField = this.$refs.inputFieldmarkpending[nextRowIndex];

          if (nextInputField) {
            nextInputField.focus();
          }
        });
      }
    }
    },
    async handleEnterKeyNote(type,rowIndex, columnName) {
      if(type == 'submited'){
      const nextRowIndex = rowIndex + 1;

      if (this.studentsEvaluationSubmitedList[nextRowIndex]) {
        this.$nextTick(() => {
          const nextInputField = this.$refs.inputFieldFeedbackSubmitted[nextRowIndex];

          if (nextInputField) {
            nextInputField.focus();
          }
        });
      }
     }
     if(type == 'pending'){
      const nextRowIndex = rowIndex + 1;

      if (this.studentsEvaluationPendingList[nextRowIndex]) {
        this.$nextTick(() => {
          const nextInputField = this.$refs.inputFieldFeedbackpending[nextRowIndex];

          if (nextInputField) {
            nextInputField.focus();
          }
        });
      }
     }
    },

    async confirmDelete(id) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viDelete(
          `/academic/assignmentDelete/${id}`,
          userData.token
        );

        if (response.isSuccess) {
          this.getAssignmentList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async getSubjectList() {
      this.teacherAllowSubjects = [];

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/class/getSubjectAssignClassList?viewType=list&classId=${this.classId}`,
          userData.token
        );

        if (response.isSuccess) {
          this.subjectList = response.data;
          if (this.subjectList.length > 0) {
            if (this.assignedSubjects.length > 0) {
              const filteredItems = this.subjectList.filter((item) =>
                this.assignedSubjects.includes(item._id.toString())
              );

              this.teacherAllowSubjects = filteredItems;
            } else {
              this.teacherAllowSubjects = this.subjectList;
            }
          }
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    getClassName(classId) {
      var className = this.classList.filter((x) => x._id == classId)[0]
        .className;
      return className;
    },

    getSectionName(sectionId) {
      var sectionDetails = this.allSectionList.find((x) => x._id == sectionId);
      return sectionDetails.name ? sectionDetails.name : "";
    },

    async getAssignmentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXPost(
          "/academic/assignments",
          this.assignmentDetails,
          userData.token
        );
        if (response.isSuccess) {
          var result = secureUI.secureGet(response.data);

          this.assignmentList = result.records.data;

          if (this.assignmentList.length > 0) {
            let studentAssignmentStatusList = [];
            for (let x of this.assignmentList) {
              if (x.studentAssignment.length > 0) {
                studentAssignmentStatusList =
                  studentAssignmentStatusList.concat(x.studentAssignment);
              }
            }

            if (studentAssignmentStatusList.length > 0) {
              this.submittedCount = studentAssignmentStatusList.filter(
                (x) =>
                  ( x.status == "Submitted" || x.status == "Late Submission") &&
                  x.classRoom.toString() == this.classRoom.toString()
              ).length;
              this.pendingCount = studentAssignmentStatusList.filter(
                (x) => x.classRoom.toString() == this.classRoom.toString()
              ).length;

              this.completedCount = studentAssignmentStatusList.filter(
                (x) =>
                  x.status == "Completed" &&
                  x.classRoom.toString() == this.classRoom.toString()
              ).length;

              this.dueCount = studentAssignmentStatusList.filter(
                (x) =>
                  x.status == "Pending" &&
                  x.classRoom.toString() == this.classRoom.toString()
              ).length;
              //  return submittedDetails.length;

              // dueCount
            }
          }

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    async updateFile(docType) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const docFileDetails = this.$refs[docType].files[0];

        if (docFileDetails) {
          const fd = new FormData();

          fd.append(
            "doc",
            docFileDetails,
            docFileDetails.name,
            docFileDetails.actType
          );

          const response = await ViService.viPutOnly(
            `/document/assignmentFile`,
            fd,
            userData.token
          );

          if (response.isSuccess) {
            this.asgntDetails.attachments.push({
              name: docFileDetails.name,
              url: response.value.url,
              date: new Date(),
            });

            // //  this.updateTeacherMaterials()
          } else {
            console.log("response", response);
          }
        }

        document.getElementById("importExportID").value = null;
      }
    },
    setActiveTab(index) {
      this.currentTab = index;

      (this.selectedAll = false), (this.selectedAllOverdue = false);
      // for(let x of this.notPaidFeePlanList){
      //       x['isSelected'] = false
      // }

      // for(let x of this.overDueFeePlanList){
      //       x['isSelected'] = false
      // }
    },
    async saveAssignment() {
      let isFormValid = false;

      await this.$validator.validateAll("assignmentValidate").then((result) => {
        isFormValid = result;
      });
      if (isFormValid) {
        if (this.asgntDetails.assignType == "class") {
          let selectedClassList = [];
          this.classList.forEach((x) => {
            if (x.isSelected) {
              selectedClassList.push(x._id);
            }
          });
          this.asgntDetails.classes = selectedClassList;
        } else if (this.asgntDetails.assignType == "classRoom") {
          let selectedSectionList = [];
          this.sectionList.forEach((x) => {
            if (x.isSelected) {
              selectedSectionList.push(x._id);
            }
          });
          this.asgntDetails.classrooms = selectedSectionList;
          this.asgntDetails["selectedClass"] = this.selectedClass;
        } else if (this.asgntDetails.assignType == "student") {
          let selectedStudentList = [];
          this.studentList.forEach((x) => {
            if (x.isSelected) {
              selectedStudentList.push(x._id);
            }
          });
          this.asgntDetails.students = selectedStudentList;
        }
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          let allowToCreate = true;

          if (this.asgntDetails.assignType == "class") {
            if (this.asgntDetails.classes.length == 0) {
              allowToCreate = false;
            }
          }
          if (this.asgntDetails.assignType == "classRoom") {
            if (this.asgntDetails.classrooms.length == 0) {
              allowToCreate = false;
            }
          }
          if (this.asgntDetails.assignType == "student") {
            if (this.asgntDetails.students.length == 0) {
              allowToCreate = false;
            }
          }
          if (allowToCreate) {
            // this.asgntDetails
            const response = await ViService.viXPost(
              "/academic/assignment/save",
              this.asgntDetails,
              userData.token
            );
            if (response.isSuccess) {
              this.$bvModal.hide("modalOne");
              this.closeModalOne();
              this.getAssignmentList();
              this.$toasted.success(response.message);
            } else {
              this.$toasted.error(response.message);
              if (
                response.message == "Your session has expired, please login"
              ) {
                localStorage.removeItem("user");
                this.$router.push("/login");
              }
            }
          } else {
            if (this.asgntDetails.assignType == "class") {
              this.$toasted.error("Please Select Minimum One Class");
            }
            if (this.asgntDetails.assignType == "classRoom") {
              this.$toasted.error("Please Select Minimum One Classroom");
            }
            if (this.asgntDetails.assignType == "student") {
              this.$toasted.error("Please Select Minimum One Student");
            }
          }
        }
      } else {
        this.$toasted.error("Please fill all the required details");
      }
    },
    async updateStudentAssignment(data,type) {
      if(type == 'Submitted'){
         let isFormValid = false;

          await this.$validator.validateAll("crtAdhocFeeValidate").then((result) => {
            isFormValid = result;
          });

          if(isFormValid){

          let userData = secureUI.sessionGet("user");

          if (!userData) {
            this.$toasted.error("Please login and do the action");
          } else {
            const response = await ViService.viXPost(
              "/academic/student/assignmentsUpdate",
              data,
              userData.token
            );
            if (response.isSuccess) {
              this.closeModalTwo();
              this.getAssignmentList();
              this.$toasted.success(response.message);
            } else {
              this.$toasted.error(response.message);
              if (response.message == "Your session has expired, please login") {
                localStorage.removeItem("user");
                this.$router.push("/login");
              }
            }
          }
          }
      }
      if(type == 'Pending'){
        let isFormValid = false;

        await this.$validator.validateAll("pendingValidate").then((result) => {
          isFormValid = result;
        });

        if(isFormValid){

        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          const response = await ViService.viXPost(
            "/academic/student/assignmentsUpdate",
            data,
            userData.token
          );
          if (response.isSuccess) {
            this.closeModalTwo();
            this.getAssignmentList();
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        }
        }
      }
      
    },

    async getStudent(assigneeType) {
      // this.selectedClass = null;
      this.selectedSection = null;
      this.classList.forEach((x) => {
        x["isSelected"] = false;
      });

      // this.classList = resultData.data;

      if (assigneeType == "class") {
        this.showClassSelection = false;
        this.showSectionSelection = false;
        this.showClassTable = true;
        this.showSectionTable = false;
        this.showStudentTable = false;
        (this.sectionList = []), (this.studentList = []);
      } else if (assigneeType == "classRoom") {
        this.showClassSelection = true;
        this.showSectionSelection = false;
        this.showClassTable = false;
        this.showSectionTable = true;
        this.showStudentTable = false;
        this.studentList = [];
      } else {
        this.showClassSelection = true;
        this.showSectionSelection = true;
        this.showClassTable = false;
        this.showSectionTable = false;
        this.showStudentTable = true;
        this.sectionList = [];
        this.studentList = [];
        this.sectionList = this.classList.find(
          (x) => x._id == this.classId
        ).sections;

        this.sectionList.forEach((y) => {
          y["isSelected"] = false;
        });
        this.getStudentList();
      }
      this.asgntDetails.assignType = assigneeType;
      this.showFullSection = true;
    },
    preViewDocument(path) {
      window.open(path, "_blank");
    },
    getSection(classId) {
      if (classId) {
        this.sectionList = [];
        this.sectionList = this.classList.find(
          (x) => x._id == classId
        ).sections;

        this.sectionList.forEach((y) => {
          y["isSelected"] = false;
        });
      } else {
        this.sectionList = [];
      }
    },
    getSectionAndSetValue(classId) {
      if (classId) {
        this.sectionList = [];
        this.sectionList = this.classList.find(
          (x) => x._id == classId
        ).sections;

        this.sectionList.forEach((y) => {
          if (y._id == this.classRoom) {
            y["isSelected"] = true;
          } else {
            y["isSelected"] = false;
          }
        });
      } else {
        this.sectionList = [];
      }
    },

    async getStudentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/contact/getStudentListForAssignment?classId=${this.classId}&sectionId=${this.classRoom}`,
          userData.token
        );
        if (response.isSuccess) {
          this.studentList = secureUI.secureGet(response.data);

          this.studentList.forEach((x) => {
            x["isSelected"] = false;
          });

          //    this.studentList.forEach((x) => {
          //   if (x.isSelected) {
          //     selectedStudentList.push(x._id);
          //   }
          // });
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },

    async getClassWithSection() {
      let userData = secureUI.sessionGet("user");

      let getClassCounts = this.$store.getters.getClassAndSectionList;

      if (getClassCounts.length == 0) {
        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          const response = await ViService.viXGet(
            `/academic/class_with_sections`,
            userData.token
          );

          if (response.isSuccess) {
            const resultData = secureUI.secureGet(response.data);

            this.$store.dispatch("setClassAndSectionList", resultData.data);

            resultData.data.forEach((x) => {
              x["isSelected"] = false;
            });

            this.classList = resultData.data;
          } else {
            this.$toasted.error(response.message);
          }
        }
      } else {
        this.classList = this.$store.getters.getClassAndSectionList;

        this.classList.forEach((x) => {
          x["isSelected"] = false;
        });
      }
    },

    async getAllSectionList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/class/getAllSectionList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);

          var finalList = [];

          finalList = details.sections;

          finalList.forEach((x) => {
            x["isSelected"] = false;
          });

          this.allSectionList = finalList;
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },

    async getAllTeacherClassAssignments() {
      let userData = secureUI.sessionGet("user");

      // let getClassCounts = this.$store.getters.getClassAndSectionList;

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/academic/teacher_class_assignment`,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);

          this.assignedStudentList = resultData;
        } else {
          this.$toasted.error(response.message);
        }
      }
    },

    addAssignment() {
      this.showClassSelection = true;
      this.showSectionSelection = false;
      this.showClassTable = false;
      this.showSectionTable = true;
      this.showStudentTable = false;
      this.asgntDetails.assignType = "classRoom";
      this.showFullSection = true;
      this.selectedClass = this.classId;
      this.getSectionAndSetValue(this.selectedClass);
      this.$bvModal.show("modalOne");
    },
    editAssignment(data) {
      this.asgntDetails = data;

      if (this.asgntDetails.publishedDate) {
        this.asgntDetails.publishedDate =
          this.asgntDetails.publishedDate.substring(0, 10);
      }

      if (this.asgntDetails.dueDate) {
        this.asgntDetails.dueDate = this.asgntDetails.dueDate.substring(0, 10);
      }
      this.$bvModal.show("modalOne");
    },
    async editAssignmentStatus(data,index) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXPost(
          "/academic/student/assignmentsUpdateStatus",
          data,
          userData.token
        );
        if (response.isSuccess) {

          let obj = {...data}

          obj.status = 'Completed'
          this.studentsEvaluationSubmitedList.splice(index,1);

         // this.closeModalTwo();
         // this.getAssignmentList();
        //  studentsEvaluationSubmitedList

        this.studentsEvaluationCompleteList.push(obj);


          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    


    openModalTwo(data) {
      if (data.studentAssignment.length > 0) {
        let filterClassRoom = data.studentAssignment.filter(
          (x) => x.classRoom.toString() == this.classRoom.toString()
        );

        this.evaluationList = filterClassRoom || []; 

        this.studentsEvaluationSubmitedList = this.evaluationList.filter((x) => x.status == "Submitted" || x.status == 'Late Submission');
        this.studentsEvaluationCompleteList = this.evaluationList.filter((x) => x.status == "Completed");
        this.studentsEvaluationPendingList = this.evaluationList.filter((x) => x.status == "Pending");

        this.termTotalMarks = data.totalMarks

        this.$bvModal.show("modalTwo");
      }
    },

    closeModalTwo() {
      this.termTotalMarks = null
      this.$bvModal.hide("modalTwo");
      this.$emit('call-parent')
    },

    closeModalOne() {
      (this.asgntDetails = {
        _id: null,
        name: "",
        type: "",
        subject: "",
        publishedDate: null,
        dueDate: null,
        submissionType: "",
        totalMarks: null,
        attachments: [],
        needEvaluation: false,
        description: "",
        assignType: "class",
        classes: [],
        classrooms: [],
        students: [],
        isDeleted: false,
        accountId: null,
        createClass: this.classId,
        createSection: this.classRoom,
        createdDate: new Date(),
        createdBy: "",
        updatedBy: "",
        updatedDate: new Date(),
      }),
        this.$bvModal.hide("modalOne");
    },
  },
  components: {
    FullCalendar,
  },
};
</script>
